body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.row {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.col-12 {
  width: 100%;
}

.col-10 {
  width: 90%;
}

.col-9 {
  width: 80%;
}

.col-8 {
  width: 70%;
}

.col-7 {
  width: 60%;
}

.col-6 {
  width: 50%;
}

.col-5 {
  width: 40%;
}

.col-3 {
  width: 30%;
}

.col-2 {
  width: 20%;
}

.col-1 {
  width: 10%;
}

.col-12,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-3,
.col-2,
.col-1 {
  padding: 0px 15px;
}

@media (max-width:760px) {
  .row {
    display: block;
  }

  .col-12,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-3,
  .col-2,
  .col-1 {
    width: 100%;
  }

  .mt-sm-5 {
    margin-top: 5rem;
  }

  .mt-sm-2 {
    margin-top: 2rem;
  }
}