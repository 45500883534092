.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.gif {
    display: flex;
    justify-content: center;
}

.content {
    text-align: center;
    margin: 3rem 0;
}

.mainHeading {
    font-size: 2rem;
    font-weight: 700;
}

.paragraph {
    font-size: 0.9rem;
    padding: 0.7rem 0;
}

.button {
    padding: 0.8rem 1rem;
    border-radius: 10px;
    outline: none;
    border: none;
    background: #5956E9;
    color: #fff;
    font-size: 1.3rem;
    cursor: pointer;
}