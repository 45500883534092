* {
    box-sizing: border-box;
}

#visiting_card_container {
    height: 242px;
    width: 336px;
    position: relative;
    color: white;
    overflow: hidden;
    border-radius: 6px;
}

.visiting_card_content,
.visiting_card_back {
    position: absolute;
    height: 192px;
    width: 336px;
    transition: transform 0.5s;
}

.visiting_card_back {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
}

.visiting_card_back_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.flipped+.visiting_card_back {
    margin: 0px;
    width: 337px;
    transform: rotateY(180deg);
    z-index: 1;
}

.flipped .card_section {
    margin-top: 200px;
}

.flipped+.visiting_card_back .visiting_card_back_content {
    transform: rotateY(180deg);
}

.visiting_card_back {
    z-index: -1;
}

.card_head {
    height: 25%;
    width: 100%;
    background-color: #0066FF;
    display: flex;
    align-items: center;
    padding: 0 0.5rem 0 0.5rem;
    gap: 1rem;


}

.brand_logo {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card_section {

    height: 75%;
    background-color: white;
    display: flex;
    padding: 0.3rem;

    color: #ffff;
}

.card_left_content,
.card_right_content {
    height: 100%;
    width: 45%;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;

}

.card_right_content {
    width: 55%;
    border-left: 1px solid #ffff;
    gap: 1rem;
    align-items: normal;
    font-size: 0.8rem;
    font-weight: 600;

}

.right_item {
    display: flex;
    overflow-wrap: anywhere;
}

.card_right_content a {
    display: flex;
    gap: 0.3rem;
    align-items: center;
}

.logo_box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border: 1px solid #2D57FF;
    background-color: #ffff;
    border-radius: 4px;
    font-size: 0.7rem;
    color: #2D57FF;
    font-weight: 800;
}

h1 {
    font-size: 1.3rem;
}

.print_btn {
    height: 20%;
    width: 100%;
    position: absolute;
    bottom: 0px;
    border-radius: 5px;
    cursor: pointer;
}

.print_btn button:hover {
    background-color: #51a4e8;
}

.print_btn button {
    background-color: #2D57FF;
    color: #ffff;
    font-size: 0.8rem;
    width: 100%;
    height: 100%;
    border: none;
    padding: 1rem;
    font-weight: 600;
}