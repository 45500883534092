@media screen and (max-width:500px) {
    .jsx-parser{
        width: 100%;
    }
    #container-wrapper{
        margin: 0px !important;
        border-radius: 0px !important;
    }
    #person-image{
        left: 50% !important;
        transform: translate(-50%);
    }
}